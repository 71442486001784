import { Box, Button, Container, Grid, Link, Typography } from "@mui/material";
import * as React from "react";
import Slider from "react-slick";
import "../../slick-carousel/my-slick.css";
import { useState } from "react";

const Hero: React.FC<any> = ({ textContent }) => {
  const options = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 12000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [safari, setSafari] = React.useState<boolean>(false);
  const [isSignupEnabled, setIsSignupEnabled] = useState(false);
  React.useEffect(() => {
    let saf =
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Safari") > -1;
    let chr =
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Chrome") > -1;
    if (chr && saf) saf = false;
    setSafari(saf);
  }, []);

  const toggleSignup = async () => {
    setIsSignupEnabled(!isSignupEnabled);
  };

  return (
    <Box sx={{ backgroundColor: "#F7F8FB" }}>
      <Container>
        <Slider className="my-slick" {...options}>
          {textContent &&
            textContent.heroItems &&
            textContent.heroItems.map((p: any, i: any) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  px: 4,
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  sx={{ py: { xs: 4, md: 10 } }}
                >
                  <Grid item xs={12} md={6}>
                    <Typography
                      fontSize={"46px"}
                      fontWeight={600}
                      lineHeight={"56px"}
                    >
                      {p.item.title}
                    </Typography>
                    <Typography variant="body1" sx={{ py: "16px" }}>
                      {p.item.order === 3 ? (
                        <>
                          {/* Move Digital Money in from all over the world and pay
                          in Canada via mobile. Get $20 cashback on your first
                          purchase and 3 months free of Extra Package.
                          <Link
                            href={p.item.link}
                            target="_blank"
                            sx={{
                              fontWeight: 500,
                              textDecoration: "none",
                              display: "flex",
                              mt: 2,
                            }}
                          >
                            Learn more about the Grapes and KOHO Partnership
                            <Box
                              component="img"
                              src="/purple-arrow.svg"
                              sx={{ ml: 1 }}
                            />
                          </Link> 8*/}
                        </>
                      ) : (
                        p.item.subText
                      )}
                    </Typography>
                    {p.item.order === 1 ? (
                      isSignupEnabled && (
                        <Button
                          variant="contained"
                          disableElevation
                          sx={{
                            mr: 2,
                            mt: 2,
                            fontWeight: "500",
                            width: {
                              xs: "100%",
                              md: "auto",
                              borderRadius: "8px",
                            },
                          }}
                          href={p.item.link}
                          target="_blank"
                        >
                          Sign Up
                        </Button>
                      )
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid
                    sx={{
                      display: { xs: "none", md: "flex" },
                      minHeight: { md: "500px" },
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                    item
                    xs={12}
                    md={6}
                  >
                    <Box
                      component="img"
                      sx={{
                        maxHeight: { md: "85%" },
                        maxWidth: { xs: "100%", md: "85%" },
                        float: { md: "right" },
                        mt: 8.5,
                      }}
                      src={p.item.image}
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default Hero;
